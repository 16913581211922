import React from "react";
import { Row, Col, Card, Typography } from "antd";
import domainExpert from "../svgs/domainExperties/domainExpertes.svg";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import { expertise } from "../data/expertiesData";
import Fade from "react-reveal/Fade";

export default function DomainExpertise() {
  return (
    <div style={{ marginTop: "70px", backgroundColor: "#eeeeee" }}>
      <Row justify="center" style={{ width: "100%" }}>
        <Col xs={0} sm={2} md={1} lg={2} xl={3}></Col>
        <Col xs={24} sm={20} md={22} lg={20} xl={18}>
          <Row gutter={16} justify="center">
            <Col xs={22} sm={12}>
              <Fade left>
                <div
                  style={{
                    backgroundImage: `url(${domainExpert})`,
                    backgroundSize: "100% 100%",
                    height: "600px",
                  }}
                ></div>
              </Fade>
            </Col>
            <Col
              xs={22}
              sm={12}
              style={{
                paddingLeft: "50px",
                paddingTop: "20px",
                textAlign: "left",
              }}
            >
              <Fade right>
                <Typography.Title>DOMAIN EXPERTISE</Typography.Title>
                <List>
                  {expertise.map((data, index) => (
                    <ListItem key={index}>
                      <ListItemAvatar>
                        <Avatar src={data.src} />
                      </ListItemAvatar>
                      <ListItemText primary={<h3>{data.title}</h3>} />
                    </ListItem>
                  ))}
                </List>
              </Fade>
            </Col>
            {/* {whyChooseUs.map((data, index) => (
              <Col
                xs={22}
                sm={16}
                md={8}
                lg={8}
                style={{ padding: "10px" }}
                key={`${data}-${index}`}
              >
                <WhyChooseUseCard
                  src={data.src}
                  title={data.title}
                  content={data.content}
                  index={index}
                />
              </Col>
            ))} */}
          </Row>
        </Col>

        <Col xs={0} sm={2} md={1} lg={2} xl={3}></Col>
      </Row>
    </div>
  );
}
