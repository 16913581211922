import axios from "axios";

const applyForJob = async (data) => {
  const url = `https://luxstek-admin-api.herokuapp.com/application/create`;
  try {
    let info = await axios.post(url, data);
    // console.log(info);
    return { data: info, error: false };
  } catch (error) {
    console.log(error.response);
    // return { ...error.response.data, error: true };
  }
};

export { applyForJob };
