import { Link } from "react-router-dom";

export const Navigation = (props) => {
  return (
    <nav
      id="menu"
      className="navbar navbar-default navbar-fixed-top"
      style={{ backgroundColor: "black" }}
    >
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <Link to="/">
            <a
              className="navbar-brand page-scroll"
              style={{ color: "#ddcfb3" }}
            >
              Luxstek
            </a>{" "}
          </Link>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <Link to="/">
                <a
                  href="#features"
                  className="page-scroll"
                  style={{ color: "#ddcfb3" }}
                >
                  Home
                </a>
              </Link>
            </li>
            <li>
              <Link to="/about-us">
                <a
                  href="#about"
                  className="page-scroll"
                  style={{ color: "#ddcfb3" }}
                >
                  About
                </a>
              </Link>
            </li>
            {/* <li>
              <Link to="/">
                <a
                  href="#services"
                  className="page-scroll"
                  style={{ color: "#ddcfb3" }}
                >
                  Services
                </a>
              </Link>
            </li> */}

            <li>
              <Link to="/careers">
                <a
                  href="/careers"
                  className="page-scroll"
                  style={{ color: "#ddcfb3" }}
                >
                  Careers
                </a>
              </Link>
            </li>
            {/* <li>
              <a
                href="#testimonials"
                className="page-scroll"
                style={{ color: "#ddcfb3" }}
              >
                Testimonials
              </a>
            </li> */}
            {/* <li>
              <a
                href="#team"
                className="page-scroll"
                style={{ color: "#ddcfb3" }}
              >
                Team
              </a>
            </li> */}
            <li>
              <Link to="/contact">
                {" "}
                <a className="page-scroll" style={{ color: "#ddcfb3" }}>
                  Contact
                </a>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
