import React from "react";
import AboutUsHeader from "./aboutUs/aboutUsHeader";
import TeamList from "./aboutUs/team/team";
import AboutUsContent from "./aboutUsContent";
import Footer2 from "./footer2";
import { Navigation } from "./navigation";

export default function AboutUs() {
  return (
    <div id="/careers">
      <Navigation />
      <AboutUsHeader />

      <div style={{ paddingTop: "50px" }}></div>
      <AboutUsContent />
      {/* <TeamList /> */}
      <div>
        <Footer2 />
      </div>
    </div>
  );
}
