import AppDev from "../svgs/1appDev.svg";
import WebDev from "../svgs/2webDev.svg";
import GameDev from "../svgs/3gamreDev.svg";
import ApiInter from "../svgs/4apiIntegration.svg";
import UiUx from "../svgs/5ui:ux.svg";
import Market from "../svgs/6market.svg";

//We also do digital and social media marketing.

const whatWeDo = [
  {
    title: "App Development",
    content: `We build customised cross platform Mobile Application using the latest technologies and frameworks, which fits into your business needs `,
    src: AppDev,
  },
  {
    title: "Web Development",
    content: ` We utilised our expertise in web development to create a variety of products and services. We also build progressive web apps(PWA) and mobile apps.`,
    src: WebDev,
  },
  {
    title: "Game Development",
    content: `Game design and development is one of the tools in our arsenal. We have a team of game designers and developers who are able to create games that are both engaging and engaging.`,
    src: GameDev,
  },
  {
    title: "Api Integration",
    content: `We help you with api integration on any platform or device with our API development services`,
    src: ApiInter,
  },
  {
    title: "UI/UX",
    content: `Our team of skillful UI/UX designers help you build a beautiful, engaging and intuitive user experience, using the latest technologies and best practices`,
    src: UiUx,
  },
  {
    title: "Digital Marketing",
    content: `We bring to you the best app design property specifies how
      overflowed content that is not displayed should be signaled to the
      user. It can be clipped, display an ellipsis (...), or display a
      custom string. Both of the following properties are required for
      text-overflow:`,
    src: Market,
  },
];

export { whatWeDo };
