import React, { Component } from "react";
import PropTypes from "prop-types";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
// import { Features } from "./components/features";
// import { About } from "./components/about";
// import { Services } from "./components/services";
// import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import Footer from "./components/footer";
import CustomCard from "./components/customs/card";
import { Grid } from "@material-ui/core";
import WhatWeDo from "./components/whatWeDo";
import Help from "./components/help";
import WhyChooseUs from "./components/whyChooseUs";
import DomainExpertise from "./components/domainExpertise";
import { testimonialData } from "./data/testimonials";
import Footer2 from "./components/footer2";

export default class Main extends Component {
  static propTypes = {
    prop: PropTypes,
  };

  render() {
    return (
      <div>
        <Navigation />
        <Header />
        <WhatWeDo />
        <DomainExpertise />
        <WhyChooseUs />
        <Testimonials data={testimonialData} />
        {/* <Team /> */}
        <Help />
        {/* <Footer /> */}
        <Footer2 />
      </div>
    );
  }
}
