import { Grid } from "@material-ui/core";
import { Row, Col } from "antd";
import React from "react";
import { whatWeDo } from "../data/whatWeDo";
import CustomCard from "./customs/card";

export default function WhatWeDo() {
  return (
    <div style={{ paddingTop: "100px" }} id="services">
      <Grid
        container
        style={{ padding: "20px" }}
        alignItems="center"
        justify="center"
      >
        <Grid item xs={12} sm={6}>
          <p style={{ color: "gold", fontSize: "20px" }}>
            We Solve Real Problems
          </p>

          <h1>What We Can Do For You Aside The Solutions We Deliver To You</h1>
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <a className="btn btn-custom btn-lg page-scroll">More Service</a>
        </Grid> */}
      </Grid>

      <div style={{ paddingTop: "40px" }}>
        <Row justify="center">
          <Col xs={0} sm={2} md={1} lg={2} xl={3}></Col>
          <Col xs={24} sm={20} md={22} lg={20} xl={18}>
            <Row gutter={16} justify="center">
              {whatWeDo.map((data, index) => (
                <Col
                  key={index}
                  xs={22}
                  sm={16}
                  md={8}
                  lg={8}
                  style={{ padding: "10px", textAlign: "center" }}
                >
                  <CustomCard
                    src={data.src}
                    title={data.title}
                    content={data.content}
                  />
                </Col>
              ))}
            </Row>
          </Col>

          <Col xs={0} sm={2} md={1} lg={2} xl={3}></Col>
        </Row>
      </div>
    </div>
  );
}
