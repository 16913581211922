import React from "react";

export default function ContactsHeader() {
  return (
    <div>
      <header id="header">
        <div className="intro2">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 intro-text">
                  <h1>
                    Contact at Luxstek
                    <span></span>
                  </h1>
                  <p>
                    {" "}
                    We give you one of the best customer support ever in the
                    world, contact us now...
                  </p>
                  {/* <a
                    href="#features"
                    className="btn btn-custom btn-lg page-scroll"
                  >
                    Learn More
                  </a>{" "} */}
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}
