import { Avatar, Grid } from "@material-ui/core";
import { motion } from "framer-motion";
import React from "react";

import Fade from "react-reveal/Fade";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  appBar: {
    [theme.breakpoints.up("sm")]: {},
  },
  cardTitle: {
    fontSize: "20px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "22px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: "24px",
    },
  },
}));

export default function CustomCard(props) {
  const classes = useStyles();
  return (
    <Fade left>
      <motion.div
        style={{
          width: "90%",
          border: "1px solid #f5f5f5",
          height: "400px",
          borderRadius: "10px 10px 10px 50px",
          backgroundColor: "white",
          boxShadow: "0 0 15px 15px #eeeeee",
          WebkitBoxShadow: "0 0 15px 15px #eeeeee",
          margin: "0 auto",
        }}
        whileHover={{
          height: "420px",
          transition: { duration: 0.25 },
        }}
      >
        <Grid container>
          <Grid xs={12} style={{ paddingTop: "20px" }}>
            <img
              src={props.src}
              style={{ width: "130px", height: "130px", borderRadius: "75px" }}
            />
          </Grid>
          <Grid xs={12} style={{ paddingTop: "10px" }}>
            <h1 className={classes.cardTitle}>{props.title}</h1>
            <p
              style={{
                textAlign: "left",
                width: "90%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                padding: "30px",
                WebkitLineClamp: 3,
                WebkitBoxOrient: "vertical",
                display: "-webkit-box",
                color: "#9e9e9e",
                lineHeight: "30px",
                maxHeight: "120px",
              }}
            >
              {props.content}
            </p>
            {/* <a href="ksjd" style={{ textAlign: "left", color: "gold" }}>
              Learn more
            </a> */}
          </Grid>
        </Grid>
      </motion.div>
    </Fade>
  );
}
