import { Grid } from "@material-ui/core";
import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { Input, Button as Btn, Icon as Ic } from "semantic-ui-react";

const quickLinks = [
  {
    name: "Services",
    link: "/services",
  },
  {
    name: "Portfolio",
    link: "/portfolio",
  },
  {
    name: "About Us",
    link: "/about-us",
  },
  {
    name: "Blog",
    link: "/blog",
  },
  {
    name: "Contact",
    link: "/contact",
  },
  {
    name: "Careers",
    link: "/careers",
  },
];

function Footer() {
  return (
    <div>
      <div style={{ backgroundColor: "#232323", height: "450px" }}>
        <Grid container style={{ paddingTop: "40px" }}>
          <Grid xl={2} lg={2} md={false}></Grid>
          <Grid xl={3} lg={3} md={4} style={{ padding: "10px" }}>
            <img
              src="https://www.colourbox.com/preview/37265878-vector-of-colorful-birds-design-on-a-black-background-wild-animals-birds-logo-or-icon-easy-editable-layered-vector-illustration.jpg"
              alt="luxnma-logo"
              style={{ width: "50%", height: "140px", display: "block" }}
            />
            <motion.p
              whileHover={{
                scale: 1.02,
                color: "#c7926c",
                transition: { duration: 0.1 },
                textAlign: "left",
              }}
              style={{
                color: "#ddcfb3",
                fontSize: "18px",
                textDecoration: "none",
                textAlign: "left",
                padding: "5px",
              }}
              onClick={() => {
                window.open("mailto:admin@luxnma.com");
              }}
            >
              mailto: admin@luxnma.com
            </motion.p>

            <p
              style={{
                fontSize: "18px",
                paddingTop: "10px",
                textAlign: "left",
                color: "#777",
                lineHeight: "25px",
              }}
            >
              Luxnma is one of the world's coming Software Company,
              <br />
              <br />
              <Btn
                as={motion.div}
                whileHover={{
                  scale: 1.08,
                  backgroundColor: `#9c27b0`,
                  transition: { duration: 0.1 },
                }}
                icon
                style={{ color: "white", backgroundColor: `#e91e63` }}
              >
                <Ic name="facebook f" />
              </Btn>
              <Btn
                icon
                as={motion.div}
                whileHover={{
                  scale: 1.08,
                  backgroundColor: `#9c27b0`,
                  transition: { duration: 0.1 },
                }}
                style={{ color: "white", backgroundColor: `#e91e63` }}
              >
                <Ic name="twitter" />
              </Btn>
              <Btn
                icon
                as={motion.div}
                whileHover={{
                  scale: 1.08,
                  backgroundColor: `#9c27b0`,
                  transition: { duration: 0.1 },
                }}
                style={{
                  color: "white",
                  backgroundColor: `#e91e63
                `,
                }}
              >
                <Ic name="instagram" />
              </Btn>
              <a href="https://www.linkedin.com/company/phubie/">
                <Btn
                  icon
                  as={motion.div}
                  whileHover={{
                    scale: 1.08,
                    backgroundColor: `#9c27b0`,
                    transition: { duration: 0.1 },
                  }}
                  style={{
                    color: "white",
                    backgroundColor: `#e91e63
                  `,
                  }}
                >
                  <Ic name="linkedin" />
                </Btn>
              </a>
            </p>
          </Grid>
          <Grid
            xl={3}
            lg={3}
            md={4}
            style={{ textAlign: "left", padding: "10px" }}
          >
            <h1 style={{ color: "white", textAlign: "left", fontSize: "30px" }}>
              Quick Links
            </h1>
            <br />
            {quickLinks.map((data, index) => (
              <Link to={data.link}>
                <motion.p
                  whileHover={{
                    scale: 1.02,
                    color: "#ddcfb3",
                    transition: { duration: 0.1 },
                  }}
                  style={{
                    color: "#777",
                    margin: "8px",
                    padding: "3px",
                    fontSize: "18px",
                    textDecoration: "none",
                  }}
                >
                  {data.name}
                </motion.p>
              </Link>
            ))}
          </Grid>
          <Grid
            xl={3}
            lg={3}
            md={4}
            style={{ textAlign: "left", padding: "10px" }}
          >
            <h1 style={{ color: "white", textAlign: "left", fontSize: "30px" }}>
              Contact Info
            </h1>
            <br />
            <p
              style={{
                fontSize: "18px",
                paddingTop: "10px",
                textAlign: "left",
                color: "#777",
                // color: "#ddcfb3",
                lineHeight: "30px",
              }}
            >
              Location: Mataheko, Jonkrobi Rd, Accra-Ghana.
              <br />
              <br />
              Phone: +918264174451 | +233501594597 | +233547036245
            </p>
          </Grid>
          <Grid xl={2} lg={2} md={false}></Grid>
        </Grid>
        <div style={{ padding: "10px", textAlign: "center" }}>
          <Grid container>
            <Grid item xs={false} sm={2}></Grid>
            <Grid item xs={12} sm={8}></Grid>
            <Grid item xs={false} sm={2}></Grid>
          </Grid>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#333338",
          height: "90px",
          textAlign: "center",
          paddingTop: "35px",
        }}
      >
        <span style={{ fontSize: "18px", color: "#777" }}>
          Copyright © 2021 Luxnma. All Right Reserved
        </span>
      </div>
    </div>
  );
}

export default Footer;
