import support from "../svgs/whyChooseUs/2csupport.svg";
import experts from "../svgs/whyChooseUs/3experts.svg";
import satisfaction from "../svgs/whyChooseUs/4fullfil.svg";

const whyChooseUs = [
  {
    title: "24/7 Live Customer Support",
    content:
      "Our team provides customers with 24/7 customer support, for softwares built by our skilled developers. You can contact us whenever and whereever for support of anyform on the software.",
    src: support,
  },
  {
    title: "Software Expert",
    content:
      "We provide you with a large pool of skilled Software Engineers associated with us, who provides you with the best solution that scales.",
    src: experts,
  },
  {
    title: "100% Customer Satisfaction",
    content:
      "We guarantee customers 100% Satisfaction for all their softwares aside the 24/7 live customer support, for all levels in Software Development",
    src: satisfaction,
  },
];

export { whyChooseUs };
