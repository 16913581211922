import { Col, Row } from "antd";
import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import {
  FacebookFilled,
  InstagramFilled,
  LinkedinFilled,
  TwitterSquareFilled,
  WhatsAppOutlined,
} from "@ant-design/icons";
import { IconButton } from "@material-ui/core";

const quickLinks = [
  {
    name: "Services",
    link: "/services",
  },
  {
    name: "Portfolio",
    link: "/portfolio",
  },
  {
    name: "About Us",
    link: "/about-us",
  },
  {
    name: "Blog",
    link: "/blog",
  },
  {
    name: "Contact",
    link: "/contact",
  },
  {
    name: "Careers",
    link: "/careers",
  },
];

export default function Footer2() {
  return (
    <div>
      <Row
        gutter={16}
        justify="right"
        align="center"
        style={{ backgroundColor: "#232323" }}
      >
        {/* <Col xs={24}>
          <Row justify="center" gutter={16} style={{ paddingTop: "60px" }}> */}
        <Col xs={22} md={10} lg={6} style={{ paddingTop: "60px" }}>
          <div style={{ height: "100%", width: "95%", margin: "0 auto" }}>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/mainsite-678c7.appspot.com/o/companyLogo%2FLuxstekWhiteL.jpg?alt=media&token=ebccfcb7-2620-45ae-a8ac-018797e69535"
              alt="luxstek-logo"
              style={{ width: "50%", height: "190px", display: "block" }}
            />

            <motion.p
              whileHover={{
                scale: 1.02,
                color: "#c7926c",
                transition: { duration: 0.1 },
                textAlign: "left",
              }}
              style={{
                color: "#ddcfb3",
                fontSize: "18px",
                textDecoration: "none",
                textAlign: "left",
                padding: "5px",
                marginTop: "5px",
              }}
              onClick={() => {
                window.open("mailto:admin@luxstek.com");
              }}
            >
              mailto: admin@luxstek.com
            </motion.p>
            <p
              style={{
                fontSize: "18px",
                paddingTop: "10px",
                textAlign: "left",
                color: "#777",
                lineHeight: "25px",
              }}
            >
              Luxstek is one of the world's coming Software Company,
              <br />
              <br />
              <a href="https://www.facebook.com/luxstek/">
                <IconButton>
                  <FacebookFilled style={{ color: "#ddcfb3" }} />
                </IconButton>
              </a>
              <a href="https://www.instagram.com/luxstek/">
                <IconButton>
                  <InstagramFilled style={{ color: "#ddcfb3" }} />
                </IconButton>
              </a>
              <a href="https://www.linkedin.com/company/luxstek/mycompany/?viewAsMember=true">
                <IconButton>
                  <LinkedinFilled style={{ color: "#ddcfb3" }} />
                </IconButton>
              </a>
              <a href="https://twitter.com/luxstek">
                <IconButton>
                  <TwitterSquareFilled style={{ color: "#ddcfb3" }} />
                </IconButton>
              </a>
              <IconButton>
                <WhatsAppOutlined style={{ color: "#ddcfb3" }} />
              </IconButton>
              <br />
              <br />
            </p>
          </div>
        </Col>
        <Col xs={22} md={10} lg={6} style={{ paddingTop: "60px" }}>
          <h1 style={{ color: "white", textAlign: "left", fontSize: "30px" }}>
            Quick Links
          </h1>
          <br />
          {quickLinks.map((data, index) => (
            <Link to={data.link}>
              <motion.p
                whileHover={{
                  scale: 1.02,
                  color: "#ddcfb3",
                  transition: { duration: 0.1 },
                }}
                style={{
                  color: "#777",
                  margin: "8px",
                  padding: "3px",
                  fontSize: "18px",
                  textDecoration: "none",
                  textAlign: "left",
                }}
              >
                {data.name}
              </motion.p>
            </Link>
          ))}
        </Col>
        <Col xs={22} md={10} lg={6} style={{ paddingTop: "60px" }}>
          <h1 style={{ color: "white", textAlign: "left", fontSize: "30px" }}>
            Contact Info
          </h1>
          <br />
          <p
            style={{
              fontSize: "18px",
              paddingTop: "10px",
              textAlign: "left",
              color: "#777",
              // color: "#ddcfb3",
              lineHeight: "30px",
            }}
          >
            <b>Location:</b> Mataheko, Jonkrobi Rd, Accra-Ghana.
            <br />
            <br />
            <b>Phone:</b>
            +918264174451 | +233501594597 | +233547036245
          </p>
        </Col>
        {/* </Row>
        </Col> */}
      </Row>
      <Row
        gutter={16}
        justify="center"
        style={{
          backgroundColor: "#333338",
          height: "90px",
          textAlign: "center",
          paddingTop: "35px",
        }}
      >
        <Col xs={24}>
          <span style={{ fontSize: "18px", color: "#777" }}>
            Copyright © 2021 Luxstek. All Right Reserved
          </span>
        </Col>
      </Row>
    </div>
  );
}
