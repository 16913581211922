import React from "react";
import { Tabs, Row, Col } from "antd";
import {
  BankOutlined,
  BookOutlined,
  UserOutlined,
  PartitionOutlined,
} from "@ant-design/icons";
import Statements from "./aboutUs/statements";
import About from "./aboutUs/about";

const { TabPane } = Tabs;

export default function AboutUsContent() {
  return (
    <div style={{ marginBottom: "50px", minHeight: "500px" }}>
      <Tabs
        tabPosition="left"
        addIcon={<UserOutlined />}
        // tabBarStyle={{ backgroundColor: "black" }}
      >
        {/* <TabPane
          tab={
            <p>
              <BankOutlined />
              About Company
            </p>
          }
          key="1"
        >
          <About />
        </TabPane> */}
        <TabPane
          tab={
            <p>
              <BookOutlined />
              Statements
            </p>
          }
          key="2"
        >
          <Statements />
        </TabPane>
        {/* <TabPane
          tab={
            <p>
              <UserOutlined />
              Management
            </p>
          }
          key="3"
        >
          Management
        </TabPane>
        <TabPane
          tab={
            <p>
              <PartitionOutlined />
              Tools and Technologies
            </p>
          }
          key="4"
        >
          Tools and Technologies
        </TabPane> */}
      </Tabs>
    </div>
  );
}
