import React from "react";
import { Row, Col, Card, Typography } from "antd";
import support from "../svgs/whyChooseUs/2csupport.svg";
import experts from "../svgs/whyChooseUs/3experts.svg";
import satisfaction from "../svgs/whyChooseUs/4fullfil.svg";
import {} from "@material-ui/core";
import { whyChooseUs } from "../data/whyChooseUs";
import WhyChooseUseCard from "./customs/whyChooseUseCard";

export default function WhyChooseUs() {
  return (
    <div style={{ marginTop: "50px" }}>
      <div className="section-title text-center">
        <h2>Why Chooose Us</h2>
      </div>
      {/* <div className="col-md-8 col-md-offset-2 section-title">
        <h2>Why Choose Us</h2>

        {/* <p>Meet a few of the brains behind the Software company</p> */}
      {/* </div>  */}
      <Row justify="center" style={{ width: "100%" }}>
        <Col xs={0} sm={2} md={1} lg={2} xl={3}></Col>
        <Col xs={24} sm={20} md={22} lg={20} xl={18}>
          <Row gutter={16} justify="center">
            {whyChooseUs.map((data, index) => (
              <Col
                xs={22}
                sm={16}
                md={8}
                lg={8}
                style={{ padding: "10px" }}
                key={`${data}-${index}`}
              >
                <WhyChooseUseCard
                  src={data.src}
                  title={data.title}
                  content={data.content}
                  index={index}
                />
              </Col>
            ))}
          </Row>
        </Col>

        <Col xs={0} sm={2} md={1} lg={2} xl={3}></Col>
      </Row>
    </div>
  );
}
