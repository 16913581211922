import ecommerce from "../svgs/expertise/ecommerce.svg";
import fintech from "../svgs/expertise/fintech.svg";
import healthcare from "../svgs/expertise/healthcare.svg";
import education from "../svgs/expertise/onlineeducation.svg";
import transportation from "../svgs/expertise/transportation.svg";
import gps from "../svgs/expertise/location.svg";
import enterprise from "../svgs/expertise/enterprise.svg";
import aiml from "../svgs/expertise/brain.svg";
import security from "../svgs/expertise/cyber-security.svg";

const expertise = [
  {
    title: "Ecommerce",
    src: ecommerce,
  },
  {
    title: "Fintech",
    src: fintech,
  },
  {
    title: "HealthCare",
    src: healthcare,
  },
  {
    title: "Education",
    src: education,
  },
  {
    title: "Transportation",
    src: transportation,
  },
  {
    title: "GPS Tracking",
    src: gps,
  },
  {
    title: "Factor and Enterprise",
    src: enterprise,
  },
  {
    title: "AI/ML",
    src: aiml,
  },
  {
    title: "Security",
    src: security,
  },
];

export { expertise };
