import { Card, Typography, Button } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import {
  FacebookFilled,
  InstagramFilled,
  TwitterSquareFilled,
  LinkedinFilled,
} from "@ant-design/icons";
import React from "react";

const { Title, Text, Paragraph } = Typography;

export default function TeamCard(props) {
  return (
    <div>
      <Card
        title={
          <div>
            <Avatar
              src={props.img}
              size={{ xs: 80, sm: 100, md: 110, lg: 130, xl: 150, xxl: 200 }}
              style={{ border: "5px solid #b2ebf2" }}
              alt={props.name}
            />
            <br />
            <br />
            <Text style={{ fontWeight: "bold" }} italic={true}>
              {props.name}
            </Text>
            <br />
            <Text type="secondary" italic={true}>
              {props.role}
            </Text>
          </div>
        }
        style={{ textAlign: "center" }}
      >
        <Card.Grid style={{ width: "100%" }}>
          <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: "more" }}>
            "{props.shortText}"
          </Paragraph>
        </Card.Grid>
        <Card.Grid style={{ width: "100%" }}>
          {props.social?.facebook ? (
            <Button
              shape="circle"
              icon={<FacebookFilled />}
              type="primary"
              href={props.social?.facebook}
              style={{ marginInline: "5px" }}
              size="large"
            />
          ) : null}{" "}
          {props.social?.instagram ? (
            <Button
              shape="circle"
              icon={<InstagramFilled />}
              type="primary"
              href={props.social?.instagram}
              style={{ marginInline: "5px" }}
              size="large"
            />
          ) : null}{" "}
          {props.social?.linkedIn ? (
            <Button
              shape="circle"
              icon={<LinkedinFilled />}
              type="primary"
              href={props.social?.linkedIn}
              style={{ marginInline: "5px" }}
              size="large"
            />
          ) : null}{" "}
          {props.social?.twitter ? (
            <Button
              shape="circle"
              icon={<TwitterSquareFilled />}
              type="primary"
              href={props.social?.twitter}
              style={{ marginInline: "5px" }}
              size="large"
            />
          ) : null}
        </Card.Grid>
      </Card>
    </div>
  );
}
