import careerBack from "../images/careers/careerBack.svg";
import frontendImage from "../images/careerImages/frontend.jpeg";
import hrIntern from "../images/careerImages/hr.jpeg";
import pmImage from "../images/careerImages/pm.jpeg";
import uiuxImage from "../images/careerImages/uiux.jpeg";
import backendImage from "../images/careerImages/backend.jpeg";
import aimlImage from "../images/careerImages/aiml.jpg";
import cloudImage from "../images/careerImages/cloud.jpg";
import mobileApp from "../images/careerImages/mobile.jpg";
import dataScienceImage from "../images/careerImages/datascience.jpg";

const careersdata = [
  {
    src: frontendImage,
    title: "Web Developer/Intern",
    req: [
      "Html, Css and Javascript",
      "React Js, Svelthe and Angular Js ",
      "Axios, Socket.io, and Redux",
    ],
  },
  {
    src: backendImage,
    title: "Backend Developer/Intern",
    req: [
      "Any language of preference",
      "Must be vexed in building Rest API's",
      "Must be comfortable building working with microservice based Architecture",
    ],
  },
  {
    src: frontendImage,
    title: "Desktop App Developer/Intern",
    req: [
      "Html, Css and Javascript",
      "Electron Js",
      "Axios, Socket.io, and Redux",
    ],
  },
  {
    src: mobileApp,
    title: "Mobile App Developer",
    req: ["Flutter, and React Native", "Provider, Socketio, SharedPreference"],
  },
  {
    src: cloudImage,
    title: "Cloud Developer",
    req: [
      "AWS, GCP, Digital Ocean and Azure",
      "Linux and Windows server Administration",
      "DevOps",
    ],
  },
  {
    src: uiuxImage,
    title: "UI/UX Design",
    req: ["Adobe XD or Sketch", "Photoshop", "Adobe Suite"],
  },
  {
    src: dataScienceImage,
    title: "Data Scientist",
    req: [
      // "Html, Css and Javascript",
      // "React Js, Svelthe and Angular Js ",
      // "Axios, Socket.io,",
    ],
  },
  {
    src: aimlImage,
    title: "AI/ML Engineer/Intern",
    req: ["Tensorflow"],
  },
  // {
  //   src: hrIntern,
  //   title: "Human Resource/Intern",
  //   req: [
  //     // "Html, Css and Javascript",
  //     // "React Js, Svelthe and Angular Js ",
  //     // "Axios, Socket.io,",
  //   ],
  // },
  // {
  //   src: pmImage,
  //   title: "Project Management/Intern",
  //   req: [
  //     // "Html, Css and Javascript",
  //     // "React Js, Svelthe and Angular Js ",
  //     // "Axios, Socket.io,",
  //   ],
  // },
];

export { careersdata };
