import React, { useState } from "react";
import Footer from "../footer";
import { Navigation } from "../navigation";
import {
  Row,
  Col,
  Card,
  Typography,
  Divider,
  Button,
  Form,
  Input,
  Checkbox,
  Select,
  Upload,
  Progress,
} from "antd";
import { careersdata } from "../../data/careers";
import { UploadOutlined } from "@ant-design/icons";
import { Button as Btn } from "@material-ui/core";
import Footer2 from "../footer2";
import CareersHeader from "./careersHeader";
import { toast } from "react-toastify";
import axios from "axios";
import { applyForJob } from "../../requests/application";

const { Option } = Select;

const { Title, Text, Paragraph } = Typography;

toast.configure();

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};
export default function Careers(props) {
  const [form] = Form.useForm();
  const [progress, setProgress] = useState(0);
  const [loading, setloading] = useState(false);
  const [uploaded, setuploaded] = useState(false);
  const [resume, setResume] = useState("");
  const [file, setfile] = useState(null);

  const uploadFile = async () => {
    if (!file) {
      toast.warning("Please select resume");
      return;
    }
    console.log(file?.type);
    if (
      file?.type === "application/pdf" ||
      file?.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      setloading(true);
      const data = new FormData();
      data.append("file", file);
      data.append("upload_preset", "qh83gzqf");
      data.append("cloud_name", "https-luxstek-com-public");

      const config = {
        headers: { "content-type": "multipart/form-data" },
        onUploadProgress: (event) => {
          const percent = Math.floor((event.loaded / event.total) * 100);
          setProgress(percent);
          if (percent === 100) {
            setTimeout(() => setProgress(0), 1000);
          }
          // onProgress({ percent: (event.loaded / event.total) * 100 });
        },
      };

      let upload = await axios.post(
        "https://api.cloudinary.com/v1_1/https-luxstek-com-public/image/upload",
        data,
        config
      );

      setResume(upload.data.secure_url);
      setuploaded(true);

      // setfile(null);
      // setloading(false);
    } else {
      toast.warning("Please select a JSON file");
      setfile(null);
    }
  };

  const onFinish = async (values) => {
    await uploadFile();
    if (uploaded) {
      let apply = await applyForJob({ ...values, resume });
      if (!apply?.error) {
        toast.success("Application sent successfully");
        form.resetFields();
      }
      // console.log(apply);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div id="/careers">
      <Navigation />
      <CareersHeader />

      <div style={{ paddingTop: "50px" }}>
        <div className="section-title text-center">
          <h2>Careers</h2>
        </div>

        <Row justify="center" style={{ width: "100%" }}>
          <Col
            xs={24}
            sm={20}
            md={22}
            lg={20}
            xl={18}
            style={{ textAlign: "center" }}
          >
            <Row gutter={16} justify="center">
              {careersdata.map((data, index) => (
                <Col
                  key={index}
                  xs={22}
                  sm={16}
                  md={8}
                  lg={8}
                  style={{ padding: "10px", textAlign: "center" }}
                >
                  <Card
                    style={{ width: 300, margin: "0 auto" }}
                    cover={
                      <img
                        alt="example"
                        src={data.src}
                        height={250}
                        style={{ width: "100%" }}
                      />
                    }
                  >
                    <Card.Grid style={{ width: "100%", height: "180px" }}>
                      <h3 style={{ fontWeight: "bold" }}>{data.title}</h3>
                      <h3 style={{ textAlign: "left", color: "#9e9e9e" }}>
                        Requirements
                      </h3>
                      <ul style={{ textAlign: "left" }}>
                        <Paragraph
                          ellipsis={{
                            rows: 2,
                            expandable: true,
                            symbol: "more",
                          }}
                        >
                          {data.req.map((datas, index) => (
                            <li
                              key={index}
                              style={{
                                display: "list-item",
                                listStyleType: "circle",
                              }}
                            >
                              {datas}
                            </li>
                          ))}
                        </Paragraph>
                      </ul>
                    </Card.Grid>

                    <Card.Grid style={{ width: "100%" }}>
                      <Btn
                        variant="contained"
                        style={{ backgroundColor: "#64dd17", color: "white" }}
                        onClick={() => {
                          // auto scroll to the form section in bottom of the page
                          window.scrollTo({
                            top: document.body.scrollHeight,
                            behavior: "smooth",
                          });
                        }}
                      >
                        Apply
                      </Btn>
                    </Card.Grid>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <div
          style={{ textAlign: "center", paddingTop: "50px", width: "100vw" }}
        >
          <h2>Online Application</h2>
          <br />
          <Row justify="center" style={{ width: "100vw", padding: "10px" }}>
            <Col xs={0} sm={5} md={1} lg={2} xl={3}></Col>
            <Col xs={24} sm={14} md={12}>
              <Form
                {...layout}
                name="control-ref"
                onFinish={onFinish}
                style={{ textAlign: "left" }}
                id="form"
                form={form}
              >
                <Form.Item
                  label={
                    <h4 style={{ textAlign: "right" }}>
                      <b>Name</b>
                    </h4>
                  }
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your name!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={
                    <h4 style={{ textAlign: "right" }}>
                      <b>Email</b>
                    </h4>
                  }
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                    {
                      type: "email",
                      message: "Please enter a valid email address",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={
                    <h4 style={{ textAlign: "right" }}>
                      <b>Number</b>
                    </h4>
                  }
                  name="number"
                  rules={[
                    {
                      required: true,
                      message: "Please input your number!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={
                    <h4 style={{ textAlign: "right" }}>
                      <b>Application Type</b>
                    </h4>
                  }
                  name="applicationType"
                  rules={[
                    {
                      required: true,
                      message: "Please select an application type",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    placeholder="Please select application type"
                  >
                    <Option value="Internship">Internship</Option>
                    <Option value="FullTime">FullTime</Option>
                    <Option value="Part-Time">Part-Time</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label={
                    <h4 style={{ textAlign: "right" }}>
                      <b>Preference</b>
                    </h4>
                  }
                  name="preference"
                  rules={[
                    {
                      required: true,
                      message: "Please select a preference",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select a field of interest"
                    // onChange={this.onGenderChange}
                    allowClear
                    // mode="multiple"
                  >
                    <Option value="Web Developer">Web Developer</Option>
                    <Option value="Desktop App Developer">
                      Desktop App Developer
                    </Option>
                    <Option value="Mobile App Developer">
                      Mobile App Developer
                    </Option>
                    <Option value="DevOps Engineer">DevOps Engineer</Option>
                    <Option value="Cloud Engineer">Cloud Engineer</Option>

                    <Option value="Data Scientist">Data Scientist</Option>
                    <Option value="Site Reliiability Engineer">
                      Site Reliiability Engineer
                    </Option>
                    <Option value="Software Engineer">Software Engineer</Option>
                    <Option value="Machine Learning Engineer">
                      Machine Learning Engineer
                    </Option>

                    <Option value="Marketing">Marketing</Option>
                    <Option value="UI/UX Developer">UI/UX Developer</Option>
                    <Option value="System Architect">System Architect</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="Add up"
                  label={
                    <h4 style={{ textAlign: "right" }}>
                      <b>Any words</b>
                    </h4>
                  }
                >
                  <Input.TextArea />
                </Form.Item>
                <Row>
                  <Col span={8}>
                    <h4 style={{ textAlign: "left" }}>
                      <b>Resume</b>
                    </h4>
                  </Col>
                  <Col span={16}>
                    <Upload
                      name="logo"
                      accept=".doc,.pdf,.docx"
                      multiple={false}
                      showUploadList={false}
                      maxCount={1}
                      beforeUpload={(fil) => {
                        setfile(fil);
                        return false;
                      }}
                    >
                      <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </Upload>
                    {progress > 0 ? <Progress percent={progress} /> : null}
                  </Col>
                </Row>
                <br />

                <Form.Item wrapperCol={{}}>
                  <Button type="primary" htmlType="submit" onClick={onFinish}>
                    Submit
                  </Button>
                </Form.Item>
              </Form>{" "}
            </Col>

            <Col xs={0} sm={5} md={1} lg={2} xl={3}></Col>
          </Row>
        </div>
      </div>

      <div>
        <Footer2 />
      </div>
    </div>
  );
}
