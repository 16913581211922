import React from "react";

export default function AboutUsHeader() {
  return (
    <div>
      <header id="header">
        <div className="intro4">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 intro-text">
                  <h1>
                    About Luxstek
                    <span></span>
                  </h1>
                  <p>Light, brilliant, hidden, and alien Technology</p>
                  {/* <a
                    href="#features"
                    className="btn btn-custom btn-lg page-scroll"
                  >
                    Learn More
                  </a>{" "} */}
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}
