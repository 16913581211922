import React from "react";

import { Row, Col } from "antd";

export default function Statements() {
  return (
    <div>
      <Row>
        <Col xs={24} sm={24} md={20} lg={18} xl={12} xxl={12}>
          <h3>
            {" "}
            <b>Vision Statement</b>{" "}
          </h3>
          <p>
            {/* 
            To help people control the world around them, to make the world an
            easy place.
              */}
            <strong>
              Luxstek's vision is to channel our services to the world through
              Niche Engineering
            </strong>{" "}
            . According to our definition, niche engineering is a type of
            software engineering in which we supply our services to our
            consumers through the space in which they are most commonly found
          </p>
          <h3>
            {" "}
            <b>Mission Statement</b>{" "}
          </h3>
          <p>
            Deliver our services to our customers, through the space they are
            mostly found.
          </p>
        </Col>
      </Row>
    </div>
  );
}
