import React from "react";
import { Typography } from "antd";
import { fade, makeStyles } from "@material-ui/core/styles";
import Fade from "react-reveal/Fade";

const useStyles = makeStyles((theme, props) => ({
  card: {
    width: "100%",
    height: "350px",
    borderBottom: (props) =>
      `5px solid ${
        props.index === 0 ? "blue" : props.index === 1 ? "#e91e63" : "#c6ff00"
      }`,
    backgroundColor: "white",
    borderRadius: "5px",
    borderTop: "1px solid #eeeeee",
    borderRight: "1px solid #eeeeee",
    borderLeft: "1px solid #eeeeee",
    padding: "10px",
    "&:hover": {
      boxShadow: "0 0 15px 15px #eeeeee",
      WebkitBoxShadow: "0 0 15px 15px #eeeeee",
    },
  },
}));

export default function WhyChooseUseCard({ children, ...props }) {
  const classes = useStyles(props);
  return (
    <Fade>
      <div className={classes.card}>
        <img
          src={props.src}
          style={{
            width: "90px",
            height: "90px",
            borderRadius: "75px",
          }}
        />
        <Typography.Title level={3}>{props.title}</Typography.Title>
        <Typography.Paragraph style={{ color: "#9e9e9e" }}>
          {props.content}
        </Typography.Paragraph>
      </div>
    </Fade>
  );
}
