import rubyPic from "../images/team/ruby.jpg";
import hillaryPic from "../images/team/hillary.jpg";
import debbyPic from "../images/team/debby.jpg";
import anmolPic from "../images/team/AnmolRaj.png";
import ayushPic from "../images/team/ayush.jpg";
import lawrencePic from "../images/team/lawrence.JPG";
import michaelPic from "../images/team/michael.jpg";
import kingPic from "../images/team/king.jpg";

const testimonialData = [
  {
    img: anmolPic,
    text: "I have enjoyed working with Luxstek. I can proudly say this is my best move. The work environment is good. Luxstek is fundamentally a strong concern with a lot of opportunities to learnsdsd",
    name: "Head of Designs",
  },

  // {
  //   img: michaelPic,
  //   text: "I'm privileged to be part of LUXSTEK! A very conducive space for unearthing solutions both for customers, workers and board! With Luxstek everyone is winning, learning and transforming in the tech world!",
  //   name: "Michael Binpong Ankomah (CMO)",
  // },
  {
    img: rubyPic,
    text: "My time with Luxstek so far has been that of an amazing one. Luxstek provides an environment for continuous improvement ( Kaizen ) and team work.  I am excited to be  part of a team that seeks to provides solutions to make lives simple.",
    name: "Ruby Bostwa (CFO)",
  },
];

export { testimonialData };
