import { Button, Col, Row, Typography } from "antd";
import { black } from "../colors";
import { motion } from "framer-motion";

export default function Help() {
  return (
    <div>
      <Row justify="center" style={{ marginTop: "50px", marginBottom: "80px" }}>
        <Col xs={1} sm={2} md={4} lg={5}></Col>
        <Col xs={22} sm={20} md={16} lg={14}>
          <div
            style={{
              backgroundColor: `${black}`,
              height: "250px",
              textAlign: "center",
              paddingTop: "25px",
              borderRadius: "20px",
            }}
          >
            <Typography.Title
              level="h2"
              style={{ color: "white", marginBottom: "25px" }}
            >
              Looking forward to build a software?
            </Typography.Title>
            <Typography.Paragraph
              style={{ color: "white", marginBottom: "25px" }}
            >
              Just submit email us your requirement. We will contact you in a
              few minutes
            </Typography.Paragraph>
            <motion.div
              className="btn btn-custom btn-lg page-scroll"
              // Bouncing animation using framer motion
              transition={{
                y: { duration: 0.4, yoyo: Infinity, ease: "easeOut" },
              }}
              animate={{
                y: ["50%", "-50%"],
              }}
              onClick={() => {
                window.open("mailto:admin@luxstek.com");
              }}
            >
              Contact Now
            </motion.div>
          </div>
        </Col>
        <Col xs={1} sm={2} md={4} lg={5}></Col>
      </Row>
    </div>
  );
}
