import { Col, Row, Typography, Form, Input, Button } from "antd";
import Title from "antd/lib/typography/Title";

import React from "react";

import Footer2 from "../footer2";
import { Navigation } from "../navigation";

import LocationOnIcon from "@material-ui/icons/LocationOn";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import { IconButton } from "@material-ui/core";
import ContactsHeader from "./contactsHeader";

const { Text, Link } = Typography;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

export default function contact() {
  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div>
      <Navigation />
      <ContactsHeader />
      <div style={{ marginBottom: "100px", paddingTop: "80px" }}>
        <Row gutter={16} justify="center">
          <Col
            xs={24}
            sm={20}
            md={18}
            lg={16}
            xl={12}
            style={{ padding: "15px", textAlign: "left" }}
          >
            <Title style={{ textAlign: "left" }}>
              Our teams are here to help you
            </Title>
          </Col>
        </Row>
        <Row gutter={16} style={{}}>
          <Col xs={0} sm={2} md={3}></Col>
          <Col xs={24} sm={20} md={18}>
            <Row>
              <Col
                xs={24}
                sm={12}
                md={8}
                style={{
                  marginTop: "10px",
                  textAlign: "left",
                  borderRight: "1px solid #e0e0e0",
                  paddingLeft: "5px",
                  paddingBottom: "5px",
                  borderRadius: "10px",
                }}
              >
                <br />
                <br />
                <Text strong style={{ fontSize: "1.7rem" }}>
                  Products Support
                </Text>
                <br />
                <br />
                <Text type="secondary" style={{ textAlign: "left" }}>
                  Feel free to connect with us by reaching us directly on{" "}
                  <span
                    style={{
                      color: "#ff8f00",
                      textDecoration: "none",
                      textAlign: "left",
                    }}
                    onClick={() => {
                      window.open("mailto:support@luxstek.com");
                    }}
                  >
                    {" "}
                    support@luxstek.com
                  </span>{" "}
                  or reach us at (+91) 826 417 4451
                </Text>
              </Col>
              <Col
                xs={24}
                sm={12}
                md={8}
                style={{
                  marginTop: "10px",
                  textAlign: "left",
                  borderRight: "1px solid #e0e0e0",
                  paddingLeft: "5px",
                  paddingBottom: "5px",
                  borderRadius: "10px",
                }}
              >
                <br />
                <br />
                <Text strong style={{ fontSize: "1.7rem" }}>
                  Marketing Support
                </Text>
                <br />
                <br />
                <Text type="secondary" style={{ textAlign: "left" }}>
                  Looking for marketing support? Feel free to drop in your
                  suggestions, feedback, queries to{" "}
                  <span
                    style={{
                      color: "#ff8f00",
                      textDecoration: "none",
                      textAlign: "left",
                    }}
                    onClick={() => {
                      window.open("mailto:support@luxstek.com");
                    }}
                  >
                    {" "}
                    marketing@luxstek.com
                  </span>{" "}
                </Text>
              </Col>
              <Col
                xs={24}
                sm={12}
                md={8}
                style={{
                  marginTop: "10px",
                  textAlign: "left",
                  paddingLeft: "5px",
                }}
              >
                <br />
                <br />
                <Text strong style={{ fontSize: "1.7rem" }}>
                  Careers
                </Text>
                <br />
                <br />
                <Text type="secondary" style={{ textAlign: "left" }}>
                  Want to join our culture and wonderful team? Feel free to drop
                  your resume at{" "}
                  <span
                    style={{
                      color: "#ff8f00",
                      textDecoration: "none",
                      textAlign: "left",
                    }}
                    onClick={() => {
                      window.open("mailto:support@luxstek.com");
                    }}
                  >
                    {" "}
                    careers@luxstek.com
                  </span>{" "}
                </Text>
              </Col>
            </Row>
          </Col>
          <Col xs={0} sm={2} md={3}></Col>
        </Row>
      </div>
      <div
        style={{
          // marginTop: "80px",
          marginBottom: "100px",
          backgroundColor: "#f5f5f5",
          paddingTop: "50px",
          paddingBottom: "70px",
        }}
      >
        <Row gutter={16} justify="center">
          <Col
            xs={24}
            sm={20}
            md={18}
            lg={16}
            xl={12}
            style={{ padding: "15px" }}
          >
            <Title>Contact Us</Title>
            <Text type="secondary" style={{ fontSize: "1.3rem" }}>
              For any enquiries on our services, relating to managament,
              Software Development, Cyber Security, Bug bounty, Maintenence,
              Design, Customer Support, Digital Marketing, SEO, etc. Contact us
              with the contact details, numbers and address below.
            </Text>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: "80px" }}>
          <Col xs={0} sm={2} md={3}></Col>
          <Col xs={24} sm={20} md={18}>
            <Row>
              <Col
                xs={24}
                sm={12}
                md={8}
                style={{ marginTop: "10px", textAlign: "center" }}
              >
                <IconButton
                  style={{
                    width: "110px",
                    height: "110px",
                    backgroundColor: "#424242",
                  }}
                >
                  <LocationOnIcon
                    style={{ width: "40px", height: "40px", color: "white" }}
                  />
                </IconButton>
                <br />
                <br />
                <Text strong>STREET ADDRESS</Text>
                <br />
                <br />
                <Text type="secondary" style={{ textAlign: "left" }}>
                  <strong>Location:</strong> Mataheko Jonkrobi Rd, <br />
                  Near the Roman Catholic Church
                </Text>
              </Col>
              <Col
                xs={24}
                sm={12}
                md={8}
                style={{ marginTop: "10px", textAlign: "center" }}
              >
                <IconButton
                  style={{
                    width: "110px",
                    height: "110px",
                    backgroundColor: "#424242",
                  }}
                >
                  <PhoneIcon
                    style={{ width: "40px", height: "40px", color: "white" }}
                  />
                </IconButton>
                <br />
                <br />
                <Text strong>PHONE NUMBER</Text>
                <br />
                <br />
                <Text type="secondary">
                  <strong>Mobile:</strong>(+233) 55 358 4324,
                  <br />
                  <strong>Office</strong> (+233) 50 159 4597 <br />
                  <strong>Phone:</strong> (+91) 826 417 4451
                </Text>
              </Col>
              <Col
                xs={24}
                sm={12}
                md={8}
                style={{ marginTop: "10px", textAlign: "center" }}
              >
                <IconButton
                  style={{
                    width: "110px",
                    height: "110px",
                    backgroundColor: "#424242",
                  }}
                >
                  <EmailIcon
                    style={{ width: "40px", height: "40px", color: "white" }}
                  />
                </IconButton>
                <br />
                <br />
                <Text strong>EMAIL ADDRESS</Text>
                <br />
                <br />
                <Text type="secondary">admin@luxstek.com</Text>
              </Col>
            </Row>
          </Col>
          <Col xs={0} sm={2} md={3}></Col>
        </Row>
      </div>

      <div style={{ textAlign: "center", paddingTop: "40px", width: "100vw" }}>
        <Title>SEND US YOUR QUERIES</Title>

        <br />
        <br />
        <Row justify="center" style={{ padding: "10px" }}>
          <Col xs={0} sm={5} md={1} lg={2} xl={3}></Col>
          <Col xs={24} sm={14} md={12}>
            <Form
              {...layout}
              name="basic"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                name="name"
                label={
                  <h4 style={{ textAlign: "right" }}>
                    <b>Name</b>
                  </h4>
                }
              >
                <Input placeholder="Name" />
              </Form.Item>
              <Form.Item
                name="email"
                label={
                  <h4 style={{ textAlign: "right" }}>
                    <b>Email</b>
                  </h4>
                }
              >
                <Input placeholder="example@123.com" />
              </Form.Item>
              <Form.Item
                name="subject"
                label={
                  <h4 style={{ textAlign: "right" }}>
                    <b>Subject</b>
                  </h4>
                }
              >
                <Input placeholder="Subject" />
              </Form.Item>
              <Form.Item
                name="message"
                label={
                  <h4 style={{ textAlign: "right" }}>
                    <b>Message</b>
                  </h4>
                }
              >
                <Input.TextArea placeholder="Your message" rows={7} />
              </Form.Item>
              <Form.Item wrapperCol={{}}>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col xs={0} sm={5} md={1} lg={2} xl={3}></Col>
        </Row>
      </div>

      <Footer2 />
    </div>
  );
}
