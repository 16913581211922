import { BrowserRouter, Route, Switch } from "react-router-dom";

import "./App.less";

import Main from "./main";
import Contact from "./components/contacts/contact";
import Careers from "./components/careers/carrers";
import AboutUs from "./components/aboutUs";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Main} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/careers" component={Careers} />
          <Route exact path="/about-us" component={AboutUs} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
